<template>
    <div class="c-app flex-row align-items-center">
        <CContainer>
            <CRow class="justify-content-center">
                <CCol md="8">
                    <CCardGroup>
                        <CCard class="p-4">
                            <CCardBody>
                                <img src="./../../assets/erika-logo.png">
                                <CForm v-if="!success">
                                    <CInput v-model="form.username"
                                            :placeholder="$t('login.label.username')"
                                            autocomplete="username email"
                                            :is-valid="validator"
                                            @keyup.enter="()=>{forgot()}"
                                            :invalid-feedback="$t('validation.messages.required')">
                                        <template #prepend-content>
                                            <CIcon name="cil-user"/>
                                        </template>
                                    </CInput>

                                    <CRow>
                                        <CCol col="6" class="text-left">
                                            <CButton
                                                @click="forgot()" color="primary" class="px-4"
                                                :disabled="!validator(form.username)"
                                            >
                                                {{ $t('login.button.recover') }}
                                            </CButton>
                                        </CCol>
                                        <CCol col="6" class="text-right">
                                            <CButton to="/login" color="link" class="px-0">{{
                                                    $t('login.button.login')
                                                }}
                                            </CButton>
                                        </CCol>
                                    </CRow>
                                </CForm>
                                <div v-else>
                                    <b-alert show variant="success">{{
                                            $t('login.message.recover_check_mail')
                                        }}
                                    </b-alert>
                                    <CRow>
                                        <CCol col="12" class="text-right">
                                            <CButton to="/login" color="link" class="px-0">{{
                                                    $t('login.button.login')
                                                }}
                                            </CButton>
                                        </CCol>
                                    </CRow>
                                </div>
                            </CCardBody>
                        </CCard>
                    </CCardGroup>
                </CCol>
            </CRow>
        </CContainer>
    </div>
</template>

<script>
export default {
    name: 'Forgot',
    data() {
        return {
            form: {
                username: null,
            },
            success: false,
        }
    },
    mounted() {
        this.$root.$children[0].loading = false
    },
    methods: {
        forgot() {
            if (this.validator(this.form.username)) {
                window.axios.post(window.apiUrl + '/forgot', this.form, {'skip_loading': true})
                    .then(response => {
                        this.success = true
                        // TODO: show message
                    })
            }
        },
        validator(val) {
            let valid = String(val)
                .toLowerCase()
                .match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
            return valid ? true : false
        }
    }
}
</script>

<style lang="scss">
body {
    background-image: url('./../../assets/web_background-erika.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
}

.c-app {
    background-color: transparent;
}
</style>